import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  http = inject(HttpClient);

  Put<T>(
    url: string,
    data?: any,
    _?: { params?: any; headers?: any; isJSON?: boolean }
  ) {
    return this.http.put<T>(
      url,
      data && data,
      _ && {params: _.params, headers: _.headers}
    );
  }

  Get<T>(url: string, _?: { params?: any; headers?: any }) {
    return this.http.get<T>(url, _ && {params: _.params, headers: _.headers});
  }

  Post<T>(
    url: string,
    data?: {},
    _?: { params?: any; headers?: any; isJSON?: boolean }
  ) {
    return this.http.post<T>(
      url,
      data && data,
      _ && {params: _.params, headers: _.headers}
    );
  }

  Delete<T>(url: string, _?: { params?: any; headers?: any }) {
    return this.http.delete<T>(url, _ && {params: _.params, headers: _.headers});
  }
}
